<template>
    <div ref="container">
        <slot v-bind="containerSizeQueryResults"></slot>
    </div>
</template>

<script setup lang="ts">
    /**
     * @module o365.vue.components.ContainerSizeQueryProvider
     */

    import { reactive, defineProps, provide, onMounted, onBeforeUnmount, defineEmits, watch, withDefaults, ref, Ref } from 'vue';
    import { containerSizeQueryProviderKey } from 'o365.modules.vue.injectionKeys.js';

    /**
     * @typedef {Object} Queries
     * @property {IQuery} [key] A key-value map of query names and their corresponding `IQuery` objects.
     */
    export type Queries = {
        [key: string]: IQuery
    }

    /**
     * @typedef {Object} IQuery
     * @property {number} [minWidth] The minimum width constraint for the query.
     * @property {number} [maxWidth] The maximum width constraint for the query.
     * @property {number} [minHeight] The minimum height constraint for the query.
     * @property {number} [maxHeight] The maximum height constraint for the query.
     * @property {ResizeObserverBoxOptions} [boxType] The box type to use for the query.
     */
    export interface IQuery {
        minWidth?: number;
        maxWidth?: number;
        minHeight?: number;
        maxHeight?: number;
        boxType?: ResizeObserverBoxOptions
    };

    /**
     * @typedef {Object} IContainerSizeQueryProviderProps
     * @property {Queries} [queries] A `Queries` object containing the custom container size queries to be used.
     * @property {boolean} [assignDefaultQueries] A boolean flag indicating whether to use the default container size queries.
     * @property {Array<string> | string} [fallback] A single query key or an array of query keys to use as fallbacks.
     * @property {ResizeObserverBoxOptions} [resizeObserverBox] The box type to use for observing the container.
     */
    export interface IContainerSizeQueryProviderProps {
        queries?: Queries;
        assignDefaultQueries?: boolean;
        fallback?: Array<string> | string;
        resizeObserverBox?: ResizeObserverBoxOptions;
    };

    /**
     * @typedef {Object} IContainerSizeQueryProviderDefaultProps
     * @property {Queries} queries A `Queries` object containing the default container size queries.
     * @property {boolean} assignDefaultQueries A boolean flag indicating whether to use the default container size queries.
     * @property {ResizeObserverBoxOptions} resizeObserverBox The box type to use for observing the container.
     */
    export interface IContainerSizeQueryProviderDefaultProps {
        queries: Queries;
        assignDefaultQueries: boolean;
        resizeObserverBox: ResizeObserverBoxOptions;
    };

    /**
     * @typedef {Object} IContainerSizeQueryResults
     * @property {boolean} [key] A key-value map of query names and their corresponding match results.
     */
    export interface IContainerSizeQueryResults {
        [key: string]: boolean;
    };

    const props = withDefaults<IContainerSizeQueryProviderProps, IContainerSizeQueryProviderDefaultProps>(defineProps<IContainerSizeQueryProviderProps>(), {
        queries: () => ({}),
        assignDefaultQueries: true,
        resizeObserverBox: 'content-box'
    });

    const container: Ref<HTMLDivElement> = ref(null);

    const defaultQueries: Queries = {
        xs: { maxWidth: 575.98, boxType: 'content-box' },
        sm: { minWidth: 576, maxWidth: 767.98, boxType: 'content-box' },
        md: { minWidth: 768, maxWidth: 991.98, boxType: 'content-box' },
        lg: { minWidth: 992, maxWidth: 1199.98, boxType: 'content-box' },
        xl: { minWidth: 1200, maxWidth: 1399.98, boxType: 'content-box' },
        xxl: { minWidth: 1400, boxType: 'content-box' },
    };

    const containerSizeQueryResults: IContainerSizeQueryResults = reactive({});
    const resizeObserver = new ResizeObserver(onResize);

    const emit = defineEmits<{ (e: 'change', queryKey: string , newValue: boolean, oldValue: boolean): void }>();

    provide(containerSizeQueryProviderKey, containerSizeQueryResults);

    // ---- Lifecycle Hooks ---- //
    onMounted(() => run());
    
    onBeforeUnmount(() => terminateResizeObserver());

    // ---- Watchers ---- //
    watch(() => props.queries, () => run());

    watch(() => props.queries, () => run());

    // ---- Methods ---- //
    function run() {
        terminateResizeObserver();
        intializeFallbacks();
        initializeContainerResizeObserver();
    }

    /**
     * Terminates the resize observer instance.
     */
    function terminateResizeObserver() {
        resizeObserver.disconnect();
    }

    /**
     * Initializes fallback values for the container size queries.
     */
    function intializeFallbacks() {
        Object.keys(props.queries).forEach(key => { containerSizeQueryResults[key] = false; });

        if (props.fallback) {
            if (Array.isArray(props.fallback)) {
                props.fallback.forEach(key => { containerSizeQueryResults[key] = true; });
            } else {
                containerSizeQueryResults[props.fallback] = true;
            }
        }
    }

    /**
     * Initializes the container resize observer instance.
     */
    function initializeContainerResizeObserver() {
        if (!container.value) {
            return;
        }

        resizeObserver.observe(container.value, {
            box: props.resizeObserverBox
        });
    }

    /**
     * Callback function for handling resize events.
     * @param {Array<ResizeObserverEntry>} entries The array of ResizeObserverEntry objects.
     * @param {ResizeObserver} observer The ResizeObserver instance.
     */
    function onResize(entries: Array<ResizeObserverEntry>, observer: ResizeObserver) {
        if (entries.length !== 1) {
            console.error('Failed to check container queries as multiple elements was returned')
            return;
        }

        const resizeObserverEntry = entries[0];

        const queries: Queries = Object.assign({}, props.assignDefaultQueries ? defaultQueries : {}, props.queries);

        for (const [key, query] of Object.entries(queries)) {
            let resizeObserverEntryBox: Readonly<Array<ResizeObserverSize>>;

            switch (query.boxType ?? 'content-box') {
                case 'border-box':
                    resizeObserverEntryBox = resizeObserverEntry.borderBoxSize;
                    break;
                case 'content-box':
                    resizeObserverEntryBox = resizeObserverEntry.contentBoxSize;
                    break;
                case 'device-pixel-content-box':
                    resizeObserverEntryBox = resizeObserverEntry.devicePixelContentBoxSize;
                    break;
            }

            const height = resizeObserverEntryBox[0].blockSize;
            const width = resizeObserverEntryBox[0].inlineSize;

            const heightMatch = (query.minHeight ?? 0) <= height && height <= (query.maxHeight ?? Number.MAX_VALUE);
            const widthMatch = (query.minWidth ?? 0) <= width && width <= (query.maxWidth ?? Number.MAX_VALUE);

            const queryMatch = heightMatch && widthMatch;

            if (containerSizeQueryResults[key] === queryMatch) {
                continue;
            }

            emit('change', key, queryMatch, containerSizeQueryResults[key]);

            containerSizeQueryResults[key] = queryMatch;
        }
    };
</script>